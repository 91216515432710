<template>
  <div class="the-navbar__user-meta flex items-center" >

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold"> {{ activeUserInfo.name }} </p>
      <small> {{ activeUserInfo.email }} </small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img v-if="activeUserInfo.photoURL" key="onlineImg" :src="activeUserInfo.photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
          <feather-icon icon="ChevronDownIcon" class="cursor-pointer" svgClasses="w-4 h-4" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <!-- <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/pages/profile').catch(() => {})">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">پروفایل</span>
          </li> -->

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/pages/user-settings').catch(() => {})">
            <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">تنظیمات کاربری</span>
          </li>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">خروج</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>


export default {
  data () {
    return {

    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    logout () {
     
      this.$http('user/logout').then((res) => {
        localStorage.clear()
        this.$router.push('/pages/login').catch(() => {})
        this.$vs.notify({
          title: 'خروج',
          text: res.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'primary'
        })
      
        // locationbar.removeItem('isauth')
      
        // this.$store.commit('auth/USER_LOGIN_CONTROL', {value :false})
        // jwt.headerSet()

        // Change role on logout. Same value as initialRole of acj.js
        // this.$acl.change('admin')
       
        // window.location.href = '/pages/login'
        // This is just for demo Purpose. If user clicks on logout -> redirect
        
      }).catch((err) => {
        this.$router.push('/pages/login').catch(() => {})
        localStorage.clear()
        this.$vs.notify({
          title: 'Error',
          text: err.response.data.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
     
    }
  },
  beforeDestroy () {
    clearInterval(1)
  }
}
</script>
