import { render, staticRenderFns } from "./TheFooter.vue?vue&type=template&id=4971052e&functional=true"
import script from "./TheFooter.vue?vue&type=script&lang=js"
export * from "./TheFooter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports