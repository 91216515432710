/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
  ==========================================================================================*/

export default [
  {
    url: "/home",
    name: "داشبورد",
    slug: "dashboard",
    icon: "HomeIcon",
  },
  {
    url: "/ticket",
    name: "تیکتها",
    slug: "home",
    icon: "AlertTriangleIcon",
  },
  {
    url: "/notification",
    name: "نوتیفیکیشن ها",
    icon: "BellIcon",
  },
  {
    url: "/salestodo",
    name: " اعلانات داخلی",
    icon: "BellIcon",
  },
  {
    name: " کاربران",
    slug: "userlist",
    icon: "UsersIcon",
    submenu: [
      {
        url: "/userlist",
        name: " کاربران",
        slug: "Haio cloud list",
      },
      {
        url: "/usersSalecooperation",
        name: "  کاربران همکار در فروش",
        slug: "Haio cloud list",

      },
      {
        url: "/importantusers",
        name: "    کاربران مهم  ",
        slug: "Haio cloud list",
      },
    ],
  },

  // {
  //   name: " سرویس ها",
  //   slug: "service list",
  //   icon: "SettingsIcon",
  //   submenu: [
  //     {
  //       url: "/VPCL",
  //       name: "  جزیره ها",
  //       slug: "VPC list",
  //       icon: "LayersIcon",
  //     },
  //     {
  //       url: "/viracloudlist",
  //       name: "  ویرا کلود ",
  //       slug: "Viracloud list",
  //       icon: "CloudIcon",
  //     },
  //     {
  //       url: "/haiocloudlist",
  //       name: "  هایو کلود ",
  //       slug: "Haio cloud list",
  //       icon: "CloudIcon",
  //     },
  //     {
  //       url: "/hostlist",
  //       name: "  هاست ",
  //       slug: "Host list",
  //       icon: "CloudIcon",
  //     },
  //     {
  //       url: "/domain",
  //       name: "  دامنه ها",
  //       slug: "Email domains",
  //       icon: "MailIcon",
  //     },
  //     {
  //       url: "/services",
  //       name: "درخواست های خدمات",
  //       slug: "User demands",
  //       icon: "AlertTriangleIcon",
  //     },
  //   ],
  // },
  {
    name: "مدیریت مالی",
    slug: "service list",
    icon: "DollarSignIcon",
    submenu: [
      {
        url: "/invoicelist",
        name: "فاکتور های صادر شده",
        slug: "invoice list",
        icon: "DollarSignIcon",
      },
      {
        url: "/postpaidusers",
        name: " پس پرداختی",
        slug: "post paid list",
        icon: "DollarSignIcon",
      },
      {
        url: "/paymentgateways",
        name: "مدیریت درگاه",
        slug: "post paid list",
      },
    ],
  },
  {
    name: "گزارش ها",
    slug: "service list",
    icon: "InfoIcon",
    submenu: [
      {
        url: "/nodereport",
        name: "گزارش نودها",
        slug: "Node repports",
      },
      {
        url: "/faktorytask",
        name: "  تسک های فکتوری",
        slug: "Faktory tasks",
      },
      {
        url: "/abuselist",
        name: "  ماشین های دارای ابیوز",
        slug: "Faktory tasks",
      },
    ],
  },
  {
    url: "",
    name: "لاگ ها",
    slug: "logs",
    icon: "TerminalIcon",
    active: false,
    submenu: [
      {
        url: "/logs/ipfilter",
        name: "آی‌پی‌های آزاد",
        slug: "ipfilter",
      },
      {
        url: "/logs/events",
        name: "رویدادها",
        slug: "events",
      },
      {
        url: "/logs/traffic-realtime",
        name: "لاگ زنده ترافیک",
        slug: "users-traffic",
      },
      {
        url: "/logs/traffic",
        name: "لاگ ترافیک",
        slug: "users-traffic",
      },
      {
        url: "/logs/ip",
        name: "لاگ آیپی",
        slug: "IP Log",
      },
    ]
  },
  {
    url: "",
    name: "سرورها",
    slug: "instances",
    icon: "TerminalIcon",
    active: false,
    submenu: [
      {
        url: "/auctionserversettings",
        name: "تنظیمات سرورهای حراجی",
        slug: "auctionserversettings",
      }
    ]
  },
];
